<template>
  <div class="req-temp-container integration-container">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">{{ pageHeader }}</h3>
        </div>
      </div>

      <div class="mt-2">
        <!-------Authentication section------->
        <template v-if="step == 1">
          <div class="mb-8">
            <p class="text-gray-font" v-html="description"></p>
            <hr class="mt-4 mb-2" />
            <p class="text-sm mb-4">Please provide the domain you use to access Box & Dice</p>
            <div class="w-1/2">
              <vs-input
                class="w-2/3 no-max-width text-gray"
                type="text"
                v-model="boxAndDice.domain"
                name="domain"
                v-validate="'required|urlFormat'"
                @change="validateDomain"
              />
              <span class="text-danger text-sm" v-show="errors.has('domain')">{{ errors.first("domain") }}</span>
            </div>
          </div>
          <div class="mb-2">
            <p class="text-sm mb-4">Please provide your API key from Box & Dice (the {{ appName }} integration one) and paste it below</p>
            <div class="w-1/2">
              <vs-input
                class="w-2/3 no-max-width text-gray"
                type="text"
                v-model="boxAndDice.apiKey"
                name="apiKey"
                v-validate="'required'"
              />
              <span class="text-danger text-sm" v-show="errors.has('apiKey')">{{ errors.first("apiKey") }}</span><br />
            </div>
            <vs-button action-btn class="mt-4" @click="testConnection" :disabled="testDisabled" v-round>Test your settings</vs-button>
            <img v-if="showIcon && testResponseIcon == 'success'" class="icon-control ml-8 align-middle" :src="successCircle" alt="alert" />
            <img v-if="showIcon && testResponseIcon == 'error'" class="icon-control ml-8 align-middle" :src="errorCircle" alt="alert" />
            <span class="ml-4 text-gray">{{ testResponseMessage }}</span><br />
            <span class="text-danger text-sm" v-show="errors.has('connection')">{{ errors.first("connection") }}</span>
          </div>
        </template>
        <!-------Authentication section------->

        <!-------Default section------->
        <template v-if="step == 2">
          <!----Disbursement---->
          <div class="mb-8">
            <p class="text-sm mb-4">Please select a default bank account for {{ appName }} to disburse funds into (for approved VPA agreements). Where no other information is available, we'll disburse to this account.</p>
            <div class="input-grp">
              <vs-select
                v-model="boxAndDice.disbursement"
                name="disbursementAccount"
                id="disbursementAccount"
                class="w-full selectExample pr-8 mb-0"
                v-validate="'required'"
                :placeholder="'Select account'"
              >
                <vs-select-item
                  :key="index"
                  :value="item.bankId"
                  :text="item.displayName ? item.displayName : item.accountName"
                  v-for="(item, index) in bankList"
                />
              </vs-select>
            </div>
            <span class="text-danger text-sm" v-show="errors.has('disbursementAccount')">{{ errors.first("disbursementAccount") }}</span>
          </div>

          <div class="mb-8">
            <p class="text-sm mb-4">Automatically create "top-up" requests for additional funding on a VPA agreement, from "adjustments" made to the authorised marketing amount in B&D</p>
            <div class="flex">
              <vs-switch name="allowForTopUp" v-model="boxAndDice.allowForTopUp" />
              <label class="ml-4">Create “top-ups” from adjustments</label>
            </div>
          </div>
          <!------Notification------->
          <div class="mb-12">
            <p class="text-sm mb-4">Please select a default recipient/s for automated notifications about your vendor agreements (optional)</p>
            <div class="flex">
              <div class="w-1/2">
                <div v-for="(newEmail, index) in boxAndDice.notifications" :key="index" class="flex flex-row mb-2">
                  <div class="mr-2">
                    <vs-input
                      size="large"
                      v-model="newEmail.email"
                      data-vv-validate-on="blur"
                      data-vv-as="email address"
                      :name="'defaultNotification'+index"
                      :id="'notification'+index"
                      class="w-full text-gray"
                      v-validate="'email'"
                      autocomplete="off"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('defaultNotification'+index)">{{ errors.first("defaultNotification"+index) }}</span>
                  </div>
                  <feather-icon v-if="deleteEmail(index)" icon="Trash2Icon" height="18px" @click="removeEmail(index)" class="text-grey integration-icon pt-2" />
                </div>
                <span @click="addEmail" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
              </div>
              <div class="flex">
                <vs-switch name="sendNotification" v-model="boxAndDice.sendNotificationToRequestUser" />
                <label class="ml-4">Send to user who requested funding, if available</label>
              </div>
              <div class="flex">
                <vs-switch name="sendNotificationToConsultants" v-model="boxAndDice.sendNotificationToConsultants" />
                <label class="ml-4">Send to consultants on listing</label>
              </div>
            </div>
          </div>
          <!-------Remittance-------->
          <div class="mb-12">
            <p class="text-sm mb-4">Please select a default recipient/s for automated remittance advice per vendor agreement (optional)</p>
            <div class="flex">
              <div class="w-1/2">
                <div v-for="(remittanceEmail, index) in boxAndDice.remittance" :key="index" class="flex flex-row mb-2">
                  <div class="mr-2 w-1/2">
                    <vs-input
                      size="large"
                      v-model="remittanceEmail.email"
                      data-vv-validate-on="blur"
                      data-vv-as="email address"
                      :name="'defaultRemittance'+index"
                      :id="'remittance'+index"
                      class="w-full text-gray"
                      v-validate="'email'"
                      autocomplete="off"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('defaultRemittance'+index)">{{ errors.first("defaultRemittance"+index) }}</span>
                  </div>
                  <feather-icon v-if="deleteDefaultRemittance(index)" icon="Trash2Icon" @click="removeDefaultRemittance(index)" class="text-grey integration-icon pt-2" />
                </div>
                <span @click="addDefaultRemittance" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
              </div>
              <div class="flex">
                <vs-switch name="sendRemittance" v-model="boxAndDice.sendRemittanceToRequestUser" />
                <label class="ml-4">Send to user who requested funding, if available</label>
              </div>
              <div class="flex">
                <vs-switch name="sendRemittanceToConsultants" v-model="boxAndDice.sendRemittanceToConsultants" />
                <label class="ml-4">Send to consultants on listing</label>
              </div>
            </div>
          </div>
          <!-----Lodgement reference------>
          <div :class="showDisbursementOverrideOption ? 'mb-12' : 'mb-16'">
            <p class="text-sm mb-4">Please select a default reference to be added to bank transfers from Rello to your account</p>
            <div class="w-1/2 custom-radio">
              <vs-radio v-model="boxAndDice.referenceType" vs-value="BOX_AND_DICE_LEDGER_REFERENCE" vs-name="referenceType">B&D ledger reference (to automate payment reconciliation)</vs-radio>
              <vs-radio v-model="boxAndDice.referenceType" vs-value="PROPERTY_ADDRESS" vs-name="referenceType" class="mt-2">Property address (typically street number & name will fit)</vs-radio>
            </div>
            <p class="text-sm mt-12 mb-4">
              Optionally add "PNOW" or "PLTR" to the bank transfer reference, to indicate nature of the payment when bank statements are imported in Box & Dice
              (when enabled this will reduce the space available for a property address, if that option is selected).</p>
            <div class="flex w-full my-4">
              <label>Append PNOW/PLTR indicator</label>
              <vs-switch name="appendPaymentType" v-model="boxAndDice.appendPaymentType" class="ml-4" />
            </div>
          </div>
          <!-----Disbursement date override----->
          <div class="mb-16" v-if="showDisbursementOverrideOption">
            <p class="text-sm">Schedule payouts for VPA agreements based on campaign start date (max 60 days from approval) or immediately on approval</p>
            <div class="w-full my-4">
              <label>Payout schedule</label>
              <div class="custom-radio mt-2">
                <vs-radio
                  v-model="boxAndDice.allowDisbursementDateOverride"
                  :vs-value="true"
                  :vs-name="`allowDisbursementDateOverride`"
                >
                  On campaign start date
                </vs-radio>
                <vs-radio
                  class="mt-2"
                  v-model="boxAndDice.allowDisbursementDateOverride"
                  :vs-value="false"
                  :vs-name="`allowDisbursementDateOverride`"
                >
                  On approval of agreement
                </vs-radio>
              </div>
            </div>
          </div>
        </template>
        <!-------Default section------->

        <!-------Offices section------->
        <template v-if="step == 3">
          <div class="mb-8">
            <p class="text-sm mb-4">
              Select the offices within B&D to sync to this Rello merchant account (each office can only be synced to one merchant account).
              You can override the default settings entered earlier, per office. If no value is entered for notifications/remittance, we won't send these emails.
            </p>
            <span class="text-danger text-sm" v-if="officeWarning">All offices are already related to other merchant accounts - contact your related offices to resolve.</span>
            <vs-table :data="boxAndDice.offices" class="stripes no-row-border-table payment-request-table payment-activity-table integration-table">
              <template slot="thead">
                <vs-th width="18%">
                  <label class="m-0 p-0 font-normal">Office</label>
                </vs-th>
                <vs-th width="10%" class="custom-center">
                  <label class="m-0 p-0 font-normal">Sync</label>
                </vs-th>
                <vs-th width="72%">
                  <label class="m-0 p-0 font-normal">Settings</label>
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                  <vs-td>
                  <div class="flex flex-col gap-y-10">
                    <p class="office-name">{{ tr.name }}</p>
                    <a v-if="tr.canSync && tr.syncOffice" @click="resetToDefault($event, indextr, tr)" class="no-underline text-interaction-primary block text-sm flex mt-4 items-center">
                      <vs-icon icon-size="small" class="refresh-icon mr-2">refresh</vs-icon>
                      Reset to default settings
                    </a>
                  </div>
                  </vs-td>
                  <vs-td>
                    <vs-checkbox v-model="tr.syncOffice" name="syncOffice" :disabled="!tr.canSync" @change="handleOfficeSync(indextr, tr)" />
                    <p v-if="!tr.canSync && tr.merchant" class="text-small text-center mt-2">
                      {{ tr.merchant.merchantId }}<br/>
                      {{ tr.merchant.companyName }}
                    </p>
                  </vs-td>
                  <!----Disbursement account---->
                  <vs-td :disabled="isOfficeDisabled(tr)">
                    <div v-if="tr.canSync" class="grid grid-cols-3 grid-flow-row gap-8">
                      <!----Disbursement account---->
                      <div>
                        <div>
                          <label>Disburse to</label>
                          <vs-select
                            v-model="tr.disbursement"
                            id="officeDisbursementAccount"
                            class="w-full mt-2"
                            data-vv-as="disbursement account"
                            :name="'officeDisbursementAccount'+indextr"
                            :placeholder="'Select account'"
                            :disabled="isOfficeDisabled(tr)"
                          >
                            <vs-select-item
                              :key="index"
                              :value="item.bankId"
                              :text="item.displayName ? item.displayName : item.accountName"
                              v-for="(item, index) in bankList"
                            />
                          </vs-select>
                          <span class="text-danger text-sm" v-show="errors.has('officeDisbursementAccount'+indextr)">{{ errors.first("officeDisbursementAccount"+indextr) }}</span>
                        </div>
                        <div class="flex mt-20">
                          <vs-switch
                            v-model="tr.allowForTopUp"
                            class="mr-4"
                            :name="`allowForTopUp${indextr}`"
                            :vs-name="`allowForTopUp${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          />Create “top-ups” from adjustments
                        </div>
                      </div>
                      <!------Notification----->
                      <div>
                        <label>Notifications</label>
                        <div v-for="(notificationEmail, index) in tr.notifications" :key="index + 0.5" class="flex flex-row mb-2">
                          <div class="mr-2" :disabled="isOfficeDisabled(tr)">
                            <vs-input
                              size="large"
                              v-model="notificationEmail.email"
                              data-vv-validate-on="blur"
                              data-vv-as="email address"
                              class="w-full text-gray no-max-width"
                              v-validate="'email'"
                              autocomplete="off"
                              :name="'notificationEmail'+indextr+index"
                              :id="'notificationEmail'+indextr+index"
                              :disabled="isOfficeDisabled(tr)"
                            />
                            <span class="text-danger text-sm" v-show="errors.has('notificationEmail'+indextr+index)">{{ errors.first("notificationEmail"+indextr+index) }}</span>
                          </div>
                          <feather-icon
                            v-if="deleteOfficeNotificationEmail(indextr) && tr.canSync && tr.syncOffice"
                            icon="Trash2Icon"
                            class="text-grey integration-icon pt-2"
                            @click="removeOfficeNotificationEmail(indextr, index)"
                          />
                        </div>
                        <span v-if="tr.canSync && tr.syncOffice" @click="addOfficeNotificationEmail(indextr)" class="text-link flex flex-row pt-2">
                          <plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address
                        </span>
                        <div class="flex mt-4">
                          <vs-switch
                            v-model="tr.sendNotificationToRequestUser"
                            class="mr-4"
                            :name="`sendNotificationToRequestUser${indextr}`"
                            :vs-name="`sendNotificationToRequestUser${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          />Send to PR creator
                        </div>
                        <div class="flex mt-4">
                          <vs-switch
                            v-model="tr.sendNotificationToConsultants"
                            class="mr-4"
                            :name="`sendNotificationToConsultants${indextr}`"
                            :vs-name="`sendNotificationToConsultants${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          />Send to consultants on listing
                        </div>
                      </div>
                      <!------Remittance----->
                      <div>
                        <label>Remittance</label>
                        <div v-for="(remittanceEmail, index) in tr.remittance" :key="index + 0.15" class="flex flex-row mb-2">
                          <div class="mr-2">
                            <vs-input
                              size="large"
                              v-model="remittanceEmail.email"
                              data-vv-validate-on="blur"
                              data-vv-as="email address"
                              class="w-full text-gray no-max-width"
                              v-validate="'email'"
                              autocomplete="off"
                              :name="'remittanceEmail'+indextr+index"
                              :id="'remittanceEmail'+indextr+index"
                              :disabled="isOfficeDisabled(tr)"
                            />
                            <span class="text-danger text-sm" v-show="errors.has('remittanceEmail'+indextr+index)">{{ errors.first("remittanceEmail"+indextr+index) }}</span>
                          </div>
                          <feather-icon
                            v-if="deleteOfficeRemittanceEmail(indextr)"
                            icon="Trash2Icon"
                            @click="removeOfficeRemittanceEmail(indextr, index)"
                            class="text-grey integration-icon pt-2"
                          />
                        </div>
                        <span v-if="tr.canSync && tr.syncOffice" @click="addOfficeRemittanceEmail(indextr)" class="text-link flex flex-row pt-2">
                          <plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address
                        </span>
                        <div class="flex mt-4">
                          <vs-switch
                            v-model="tr.sendRemittanceToRequestUser"
                            class="mr-4"
                            :name="`sendRemittanceToRequestUser${indextr}`"
                            :vs-name="`sendRemittanceToRequestUser${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          />Send to PR creator
                        </div>
                        <div class="flex mt-4">
                          <vs-switch
                            v-model="tr.sendRemittanceToConsultants"
                            class="mr-4"
                            :name="`sendRemittanceToConsultants${indextr}`"
                            :vs-name="`sendRemittanceToConsultants${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          />Send to consultants on listing
                        </div>
                      </div>
                      <!------Payout schedule----->
                      <div class="mt-8 mb-4">
                        <label>Payout schedule</label>
                        <div class="custom-radio mt-2">
                          <vs-radio
                            :vs-value="true"
                            :vs-name="`disburseOn${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                            v-model="tr.allowDisbursementDateOverride"
                          >
                            On campaign start date
                          </vs-radio>
                          <vs-radio
                            class="mt-2"
                            v-model="tr.allowDisbursementDateOverride"
                            :vs-value="false"
                            :vs-name="`disburseOn${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          >
                            On approval of agreement
                          </vs-radio>
                        </div>
                      </div>
                      <!------Lodgement reference----->
                      <div class="mt-8 mb-4">
                        <label>Lodgement reference</label>
                        <div class="custom-radio mt-2">
                          <vs-radio
                            v-model="tr.referenceType"
                            vs-value="BOX_AND_DICE_LEDGER_REFERENCE"
                            :vs-name="`referenceType${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          >
                            Use B&D ledger reference
                          </vs-radio>
                          <vs-radio
                            v-model="tr.referenceType"
                            vs-value="PROPERTY_ADDRESS"
                            class="mt-2"
                            :vs-name="`referenceType${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          >
                            Use property address
                          </vs-radio>
                        </div>
                      </div>
                      <!------Payment indicator----->
                      <div class="flex w-full mt-8 mb-4">
                        <vs-switch
                          v-model="tr.appendPaymentType" class="mr-4"
                          :name="`appendPaymentType${indextr}`"
                          :vs-name="`appendPaymentType${indextr}`"
                          :disabled="isOfficeDisabled(tr)"
                        />
                        <label>Append PNOW/PLTR indicator</label>
                      </div>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <span class="text-danger text-sm" v-show="errors.has('officeSync')">{{ errors.first("officeSync") }}</span>
          </div>
        </template>
        <!-------Offices section------->
        <div class="popup-action-btn-container bottom-0 bg-white">
          <div class="flex justify-end">
            <vs-button v-if="step == 3" size="large" action-btn v-round @click="addIntroducer()" :disabled="errors.items.length > 0">
              {{ (action == "edit") ? "Save" : "Confirm" }}
            </vs-button>
            <vs-button v-else size="large" action-btn v-round @click="nextStep()" :disabled="disableButtons">Next</vs-button>
            <vs-button @click="goBack()" size="large" action-btn class="only-border-btn ml-2" v-round>Cancel</vs-button>
          </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PlusIcon } from "vue-feather-icons";
import S3ImageGenerator from "../../components/S3ImageGenerator";

const successCircle = require("@/assets/images/success-circle.svg");
const errorCircle = require("@/assets/images/cancel.svg");

export default {
  components: { PlusIcon, S3ImageGenerator },

  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      action: "",
      description: "",
      introducerId: "",
      merchantId: "",
      boxAndDice: {
        introducerId: "",
        domain: "",
        apiKey: "",
        offices: [],
        disbursement: "",
        notifications: [{ email: "" }],
        remittance: [{ email: "" }],
        referenceType: "BOX_AND_DICE_LEDGER_REFERENCE",
        allowDisbursementDateOverride: false,
        appendPaymentType: false,
        sendNotificationToRequestUser: false,
        sendNotificationToConsultants: true,
        sendRemittanceToRequestUser: false,
        sendRemittanceToConsultants: true,
        allowForTopUp: false
      },
      testDisabled: false,
      testResponseIcon: "",
      testResponseMessage: "",
      activeIntroducer: {},
      bankList: [],
      showIcon: false,
      successCircle: successCircle,
      errorCircle: errorCircle,
      isConnectionValid: false,
      merchant: {},
      oldDomain: "",
      oldApiKey: "",
      product: null,
      step: 1,
      officeWarning: false,
      pageHeader: "Your Box & Dice account"
    };
  },

  computed: {
    showDisbursementOverrideOption() {
      return (this.product && this.product.allowDisbursementDateOverride);
    },

    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return (this.user.userType === "admin") ? this.user._id : this.user.partnerId;
    },

    isDomainUpdated() {
      if (this.boxAndDice.domain) {
        const old = new URL(this.oldDomain);

        let newDomain = this.boxAndDice.domain;

        if (!newDomain.startsWith("http://") && !newDomain.startsWith("https://")) {
          newDomain = "https://" + newDomain;
        }
        newDomain = new URL(newDomain);

        return !(old.hostname == newDomain.hostname);
      } else {
        return false;
      }
    },

    isApiKeyUpdated() {
      if (this.boxAndDice.apiKey) {
        return !(this.oldApiKey == this.boxAndDice.apiKey);
      } else {
        return false;
      }
    },

    disableButtons() {
      let disable = (this.errors.items.length > 0);

      if (this.step == 1) {
        disable = !this.isConnectionValid || (this.showIcon && this.testResponseIcon == "error") || (this.errors.items.length > 0);
      }

      return disable;
    }
  },

  mounted() {
    this.$validator.extend("urlFormat", {
      getMessage: () =>
        "The domain field is not a valid url.",
      validate: value =>
        value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/gi
        ) !== null
    });
    this.getBoxAndDiceProduct();
    this.action = this.$route.params.type;
    this.introducerId = this.$route.params.introducerId;
    this.checkAdminLogin();
    this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
    this.getIntroducerByMerchantId();
  },

  methods: {
    ...mapActions("linkRequest", ["createIntroducerLinkRequest"]),
    ...mapActions("partner", ["testBoxAndDiceConnection", "updateBoxAndDiceSettings"]),
    ...mapActions("introducer", ["fetchIntroducerMerchantLink"]),
    ...mapActions("bank", ["fetchBanksAccountByPartnerId"]),
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("setting", ["fetchBoxAndDiceProduct"]),

    async getBoxAndDiceProduct() {
      await this.fetchBoxAndDiceProduct().then((res) => {
        this.product = res.data.data;
      });
    },

    async getIntroducerByMerchantId() {
      await this.fetchIntroducerMerchantLink({ merchantId: this.partnerId, introducerId: this.introducerId }).then((res) => {
        this.activeIntroducer = res.data.data;

        if (this.action == "create" && !this.activeIntroducer.isBoxDiceEnabled) {
          this.showToastMessage("Error", "Introducer has not been enabled for Box & Dice integration", "error");
          this.goBack();
        } else if (this.action == "edit" && !this.activeIntroducer.linkRequest) {
          this.showToastMessage("Error", "Introducer has not been linked with your merchant", "error");
          this.goBack();
        }

        if (this.action == "create") {
          this.description = `You're about to <b>enable</b> the integration with <b class="uppercase">${this.activeIntroducer.name}</b>, allowing <b class="uppercase">${this.activeIntroducer.name}</b> to access your account to create requests, receive updates, etc. Check our help centre for further info on the settings below.`;
        } else if (this.action == "edit") {
          this.description = "Be careful when editing these details - changes will be applied immediately, incorrect authentication details will prevent your integration from operating";
        }

        this.getBanks();
      }).catch(ex => {
        this.showToastMessage("Introducers", ex.data.message, "error");
        this.goBack();
      });
    },

    async checkAdminLogin() {
      this.$vs.loading();
      await this.checkLogin().then(result => {
        this.$vs.loading.close();
        this.merchant = result.data.data;

        if (this.action == "edit") {
          this.handleEdit();
        }
      });
    },

    async handleEdit() {
      const connection = this.merchant.boxAndDice.connections.find((item) => item.introducerId == this.introducerId);

      if (connection) {
        this.oldDomain = connection.domain;
        this.oldApiKey = connection.apiKey;

        let merchantOffices = [...connection.offices];
        let latestOffice = [];

        const defaultConnection = merchantOffices.find((item) => item.name == "default");
        const existingOfficeIds = merchantOffices.map(item => item.officeId).filter(item => item);

        const data = {
          partnerId: this.partnerId,
          payload: {
            domain: connection.domain,
            apiKey: connection.apiKey,
            merchantId: this.merchantId,
          }
        };

        this.$vs.loading();
        await this.testBoxAndDiceConnection(data).then((result) => {
          const updatedOffices = result.data.data.data; // offices from latest api call
          const updatedOfficeIds = updatedOffices.map(item => item.id);

          // get array of office id from existing offices that doesn't exist in updated list
          const deletedOffice = existingOfficeIds.filter(id => !updatedOfficeIds.includes(id));

          // remove deleted office from merchantOffices array
          for (let i = 0; i < deletedOffice.length; i++) {
            const removeIndex = merchantOffices.findIndex((item) => item.officeId == deletedOffice[i]);

            if (removeIndex >= 0) {
              merchantOffices.splice(removeIndex, 1);
            }
          }

          for (let i = 0; i < updatedOffices.length; i++) {
            const officeData = updatedOffices[i];
            const officeIndex = merchantOffices.findIndex((item) => item.officeId == officeData.id); // get index of office as per current configuration

            // if office id not found, add office to array
            if (officeIndex < 0) {
              latestOffice.push({
                name: officeData.name,
                officeId: officeData.id,
                disbursement: "",
                notifications: [],
                remittance: [],
                sendNotificationToRequestUser: false,
                sendNotificationToConsultants: true,
                sendRemittanceToRequestUser: false,
                sendRemittanceToConsultants: true,
                referenceType: null,
                appendPaymentType: false,
                allowDisbursementDateOverride: null,
                merchant: officeData.merchant,
                canSync: officeData.canSync,
                syncOffice: officeData.syncOffice,
                allowForTopUp: officeData.allowForTopUp
              });
            } else if (officeIndex >= 0 && (merchantOffices[officeIndex].name != officeData.name)) { // if office exists, but office name is updated, update name in array
              latestOffice.push({
                ...merchantOffices[officeIndex],
                name: officeData.name,
                syncOffice: true,
                canSync: true,
              });
            } else {
              latestOffice.push({
                ...merchantOffices[officeIndex],
                syncOffice: true,
                canSync: true,
              });
            }
          }
        }).catch((ex) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: "Box and dice integration",
            text: ex.data.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });

        const defaultNotification = defaultConnection.notifications.filter((item) => item);
        const defaultRemittance = defaultConnection.remittance.filter((item) => item);

        this.boxAndDice = {
          domain: connection.domain,
          apiKey: connection.apiKey,
          introducerId: connection.introducerId,
          disbursement: defaultConnection ? defaultConnection.disbursement : "",
          notifications: defaultNotification.length ? defaultNotification.map((item) => { return { email: item }}) : [{ email: "" }],
          remittance: defaultRemittance.length ? defaultRemittance.map((item) => { return { email: item }}) : [{ email: "" }],
          allowDisbursementDateOverride: defaultConnection.allowDisbursementDateOverride || false,
          sendNotificationToRequestUser: defaultConnection.sendNotificationToRequestUser || false,
          sendNotificationToConsultants: defaultConnection.sendNotificationToConsultants || false,
          sendRemittanceToRequestUser: defaultConnection.sendRemittanceToRequestUser || false,
          sendRemittanceToConsultants: defaultConnection.sendRemittanceToConsultants || false,
          appendPaymentType: defaultConnection.appendPaymentType || false,
          referenceType: defaultConnection.referenceType || "BOX_AND_DICE_LEDGER_REFERENCE",
          allowForTopUp: defaultConnection.allowForTopUp || false,
          offices: latestOffice.map((item) => {
            return {
              officeId: item.officeId,
              name: item.name,
              disbursement: item.disbursement,
              notifications: item.notifications.length ? item.notifications.map(item => { return { email: item } }) : [{ email: "" }],
              remittance: item.remittance.length ? item.remittance.map(item => { return { email: item } }) : [{ email: "" }],
              sendNotificationToRequestUser: item.sendNotificationToRequestUser || false,
              sendNotificationToConsultants: item.sendNotificationToConsultants || false,
              sendRemittanceToRequestUser: item.sendRemittanceToRequestUser || false,
              sendRemittanceToConsultants: item.sendRemittanceToConsultants || false,
              referenceType: item.referenceType || null,
              appendPaymentType: item.appendPaymentType || false,
              allowDisbursementDateOverride: item.hasOwnProperty("allowDisbursementDateOverride") ? item.allowDisbursementDateOverride : null,
              merchant: item.merchant || null,
              canSync: item.canSync || false,
              syncOffice: item.syncOffice || false,
              allowForTopUp: item.allowForTopUp || false
            }
          })
        }

        this.$vs.loading.close();
      }
    },

    goBack() {
      this.$router.push({ name: "integrations" });
    },

    async addIntroducer() {
      let obj = {
        merchantId: this.partnerId,
        introducerId: this.introducerId,
      };

      if (this.activeIntroducer.isBoxDiceEnabled) {
        const validate = await this.$validator.validateAll();

        if (!validate || this.errors.items.length) {
          return false;
        }

        if ((this.action == "create" && !this.isConnectionValid) || (this.action == "edit" && (this.isDomainUpdated || this.isApiKeyUpdated) && !this.isConnectionValid)) {
          this.errors.add({
            field: "connection",
            msg: "Please test connection before proceeding."
          });
          return false;
        }

        const canSync = this.boxAndDice.offices.find((item) => item.canSync);
        const syncedOffice = this.boxAndDice.offices.filter((item) => item.canSync && item.syncOffice);

        if (canSync && (!syncedOffice || !syncedOffice.length)) {
          this.errors.add({
            field: "officeSync",
            msg: "Please sync atleast one office"
          });
          return false;
        }

        obj.boxAndDice = {
          introducerId: this.introducerId,
          domain: this.boxAndDice.domain,
          apiKey: this.boxAndDice.apiKey,
          offices: []
        };

        obj.boxAndDice.offices.push({
          name: "default",
          disbursement: this.boxAndDice.disbursement,
          notifications: this.boxAndDice.notifications.map(item => { return item.email }).filter((item) => item),
          remittance: this.boxAndDice.remittance.map(item => { return item.email }).filter((item) => item),
          sendNotificationToRequestUser: this.boxAndDice.sendNotificationToRequestUser,
          sendNotificationToConsultants: this.boxAndDice.sendNotificationToConsultants,
          sendRemittanceToRequestUser: this.boxAndDice.sendRemittanceToRequestUser,
          sendRemittanceToConsultants: this.boxAndDice.sendRemittanceToConsultants,
          referenceType: this.boxAndDice.referenceType,
          appendPaymentType: this.boxAndDice.appendPaymentType,
          allowDisbursementDateOverride: this.boxAndDice.allowDisbursementDateOverride,
          allowForTopUp: this.boxAndDice.allowForTopUp,
        });

        if (this.boxAndDice.offices && this.boxAndDice.offices.length) {
          const syncedOffice = this.boxAndDice.offices.filter((item) => item.canSync && item.syncOffice);
          syncedOffice.forEach((item) => {
            const payload = {
              officeId: item.officeId,
              name: item.name,
              disbursement: item.disbursement,
              notifications: item.notifications.length ? item.notifications.map(item => { return item.email }).filter((item) => item) : [],
              remittance: item.remittance.length ? item.remittance.map(item => { return item.email }).filter((item) => item) : [],
              sendNotificationToRequestUser: item.sendNotificationToRequestUser,
              sendNotificationToConsultants: item.sendNotificationToConsultants,
              sendRemittanceToRequestUser: item.sendRemittanceToRequestUser,
              sendRemittanceToConsultants: item.sendRemittanceToConsultants,
              referenceType: item.referenceType,
              appendPaymentType: item.appendPaymentType,
              allowDisbursementDateOverride: item.allowDisbursementDateOverride,
              allowForTopUp: item.allowForTopUp,
            }

            obj.boxAndDice.offices.push(payload);
          });
        }

        obj.boxAndDice = JSON.stringify(obj.boxAndDice);
      }

      this.$vs.loading();

      if (this.action == "create") {
        await this.createIntroducerLinkRequest(obj).then((res) => {
          this.$vs.loading.close();
          this.showToastMessage(res.data.title, res.data.message, "success");
          this.goBack();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showToastMessage("Link request", ex.data.message, "error");
        });
      } else if (this.action == "edit") {
        await this.updateBoxAndDiceSettings(obj).then((res) => {
          this.$vs.loading.close();
          this.showToastMessage(res.data.title, res.data.message, "success");
          this.goBack();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showToastMessage("Integration settings", ex.data.message, "error");
        });
      }
    },

    async getBanks() {
      await this.fetchBanksAccountByPartnerId(this.partnerId).then((result) => {
        if (result) {
          const banksList = result.data.data;
          this.bankList = banksList.filter(item => item.isUsedForSettlement).map((item) => {
            return {
              bankId: item._id,
              displayName: item.displayName ? item.displayName : item.accountName,
            };
          });
        }
      }).catch((ex) => {
        this.$vs.notify({
          title: "Bank account",
          text: "We are unable to process your request at this time.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right"
        });
      });
    },

    removeEmail(index) {
      this.boxAndDice.notifications.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "defaultNotification" + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },

    addEmail() {
      this.boxAndDice.notifications.push({ email: "" });
    },

    deleteEmail() {
      return this.boxAndDice.notifications.length > 1;
    },

    addDefaultRemittance() {
      this.boxAndDice.remittance.push({ email: "" });
    },

    removeDefaultRemittance(index) {
      this.boxAndDice.remittance.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "defaultRemittance" + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },

    deleteDefaultRemittance() {
      return this.boxAndDice.remittance.length > 1;
    },

    async testConnection() {
      let error = false;

      if (this.errors.has("connection")) {
        this.errors.remove("connection");
      }

      if (!this.boxAndDice.domain) {
        error = true;
        this.errors.add({
          field: "domain",
          msg: "Please enter a valid domain"
        });
      }

      if (!this.boxAndDice.apiKey) {
        error = true;
        this.errors.add({
          field: "apiKey",
          msg: "Please enter a api key"
        });
      }

      if (error) {
        return;
      }

      this.testDisabled = true;

      const data = {
        partnerId: this.partnerId,
        payload: {
          domain: this.boxAndDice.domain,
          apiKey: this.boxAndDice.apiKey,
          merchantId: this.merchantId,
        }
      };

      this.$vs.loading();

      // DELAYING API CALL BY 5 SECONDS ONLY
      await new Promise(resolve => setTimeout(resolve, Math.random() * 5000));

      await this.testBoxAndDiceConnection(data).then((result) => {
        this.testDisabled = false;

        let statusCode = result.data.data.statusCode;
        this.testResponseIcon = (statusCode == 200) ? "success" : "error";

        if (statusCode == 200) {
          this.testResponseMessage = "Success!";
          this.isConnectionValid = true;

          if (this.action == "create" && !this.boxAndDice.offices.length) {
            this.boxAndDice.offices.push(...result.data.data.data.filter((item) => {
              item.officeId = item.id,
              item.disbursement = "";
              item.notifications = [{ email: "" }];
              item.remittance = [{ email: "" }];
              item.sendNotificationToRequestUser = false;
              item.sendNotificationToConsultants = true;
              item.sendRemittanceToRequestUser = false;
              item.sendRemittanceToConsultants = true;
              item.allowDisbursementDateOverride = null;
              item.appendPaymentType = false;
              item.referenceType = null;

              return item;
            }));
          } else if (this.action == "edit" && (this.isDomainUpdated || this.isApiKeyUpdated || !this.boxAndDice.offices.length)) {
            this.boxAndDice.offices = [...result.data.data.data];
            this.handleDefaultSettings();
          }
        } else if (statusCode == 401) {
          this.testResponseMessage = "Authentication failed";
        } else if (statusCode == 404) {
          this.testResponseMessage = "Incorrect domain";
        } else {
          this.testResponseMessage = `Unknown error - contact ${this.appName} support`;
        }

        this.showIcon = true;
        this.$vs.loading.close();
      }).catch((ex) => {
        let statusCode = ex.data.data.statusCode;

        if (statusCode == 404) {
          this.testResponseMessage = "Incorrect domain";
        } else {
          this.testResponseMessage = `Unknown error - contact ${this.appName} support`;
        }

        this.testDisabled = false;
        this.testResponseIcon = "error";
        this.showIcon = true;
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Test connection",
          text: ex.data.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
      });
    },

    addOfficeNotificationEmail(officeIndex) {
      this.boxAndDice.offices[officeIndex].notifications.push({ email: "" });
      this.$forceUpdate();
    },

    deleteOfficeNotificationEmail(officeIndex) {
      return this.boxAndDice.offices[officeIndex].notifications.length > 1;
    },

    removeOfficeNotificationEmail(officeIndex, index) {
      this.boxAndDice.offices[officeIndex].notifications.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "notificationEmail" + officeIndex + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
      this.$forceUpdate();
    },

    addOfficeRemittanceEmail(officeIndex) {
      this.boxAndDice.offices[officeIndex].remittance.push({ email: "" });
      this.$forceUpdate();
    },

    deleteOfficeRemittanceEmail(officeIndex) {
      return this.boxAndDice.offices[officeIndex].remittance.length > 1;
    },

    removeOfficeRemittanceEmail(officeIndex, index) {
      this.boxAndDice.offices[officeIndex].remittance.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "remittanceEmail" + officeIndex + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
      this.$forceUpdate();
    },

    handleDefaultSettings() {
      if (this.boxAndDice.offices && this.boxAndDice.offices.length) {
        let defaultNotifications = this.boxAndDice.notifications.map(item => { return item.email }).filter((item) => item);
        defaultNotifications = defaultNotifications.length ? defaultNotifications.map((item) => { return { email: item } }) : [{ email: "" }];

        let defaultRemittance = this.boxAndDice.remittance.map(item => { return item.email }).filter((item) => item);
        defaultRemittance = defaultRemittance.length ? defaultRemittance.map((item) => { return { email: item } }) : [{ email: "" }];

        this.boxAndDice.offices.forEach((item) => {
          if (!item.officeId) {
            item.officeId = item.id;
          }

          if (!item.disbursement && this.boxAndDice.disbursement) {
            item.disbursement = this.boxAndDice.disbursement;
          }

          let officeNotification = item.notifications.map(item => { return item.email }).filter((item) => item);
          if ((this.action == "create") || (!officeNotification || ! officeNotification.length)) {
            item.notifications = JSON.parse(JSON.stringify(defaultNotifications));
          }

          let officeRemittance = item.remittance.map(item => { return item.email }).filter((item) => item);
          if ((this.action == "create") || (!officeRemittance || ! officeRemittance.length)) {
            item.remittance = JSON.parse(JSON.stringify(defaultRemittance));
          }
        });
      }
    },

    validateDomain() {
      const url = new URL(this.boxAndDice.domain);

      if (url.pathname && Object.keys(url.pathname).length > 1) {
        this.errors.add({
          field: "domain",
          msg: "Please only provide domain name"
        });
      }

      this.isDomainUpdated();
    },

    async nextStep() {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      this.step += 1;

      if (this.step == 2) {
        this.pageHeader = "Default settings";
      }

      if (this.step == 3) {
        this.pageHeader = "Configure settings for each office";
        const canSync = this.boxAndDice.offices.filter((item) => item.canSync);

        if (!canSync || !canSync.length) {
          this.officeWarning = true;
        }
      }

      window.scrollTo(0, 0);
    },

    handleOfficeSync(index, data) {
      if (data.syncOffice) {
        if (this.errors.has("officeSync")) {
          this.errors.remove("officeSync");
        }

        let defaultNotifications = this.boxAndDice.notifications.map(item => { return item.email }).filter((item) => item);
        defaultNotifications = defaultNotifications.length ? defaultNotifications.map((item) => { return { email: item } }) : [{ email: "" }];

        let defaultRemittance = this.boxAndDice.remittance.map(item => { return item.email }).filter((item) => item);
        defaultRemittance = defaultRemittance.length ? defaultRemittance.map((item) => { return { email: item } }) : [{ email: "" }];

        this.boxAndDice.offices[index].disbursement = this.boxAndDice.disbursement;

        let officeNotification = this.boxAndDice.offices[index].notifications.map(item => { return item.email }).filter((item) => item);

        if ((this.action == "create") || (!officeNotification || ! officeNotification.length)) {
          this.boxAndDice.offices[index].notifications = JSON.parse(JSON.stringify(defaultNotifications));
        }

        let officeRemittance = this.boxAndDice.offices[index].remittance.map(item => { return item.email }).filter((item) => item);

        if ((this.action == "create") || (!officeRemittance || ! officeRemittance.length)) {
          this.boxAndDice.offices[index].remittance = JSON.parse(JSON.stringify(defaultRemittance));
        }

        this.boxAndDice.offices[index].sendNotificationToRequestUser = this.boxAndDice.sendNotificationToRequestUser;
        this.boxAndDice.offices[index].sendNotificationToConsultants = this.boxAndDice.sendNotificationToConsultants;
        this.boxAndDice.offices[index].sendRemittanceToRequestUser = this.boxAndDice.sendRemittanceToRequestUser;
        this.boxAndDice.offices[index].sendRemittanceToConsultants = this.boxAndDice.sendRemittanceToConsultants;
        this.boxAndDice.offices[index].allowDisbursementDateOverride = this.boxAndDice.allowDisbursementDateOverride;
        this.boxAndDice.offices[index].referenceType = this.boxAndDice.referenceType;
        this.boxAndDice.offices[index].appendPaymentType = this.boxAndDice.appendPaymentType;
      } else {
        this.boxAndDice.offices[index].disbursement = "";
        this.boxAndDice.offices[index].notifications = [{ email: "" }];
        this.boxAndDice.offices[index].remittance = [{ email: "" }];
        this.boxAndDice.offices[index].sendNotificationToRequestUser = false;
        this.boxAndDice.offices[index].sendNotificationToConsultants = false;
        this.boxAndDice.offices[index].sendRemittanceToRequestUser = false;
        this.boxAndDice.offices[index].sendRemittanceToConsultants = false;
        this.boxAndDice.offices[index].allowDisbursementDateOverride = null;
        this.boxAndDice.offices[index].referenceType = null;
        this.boxAndDice.offices[index].appendPaymentType = false;
      }
    },

    resetToDefault(event, index, data) {
      if (!data.syncOffice) {
        // Prevent default behavior and stop propagation
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      this.boxAndDice.offices[index].disbursement = this.boxAndDice.disbursement;
      this.boxAndDice.offices[index].notifications = JSON.parse(JSON.stringify(this.boxAndDice.notifications));
      this.boxAndDice.offices[index].remittance = JSON.parse(JSON.stringify(this.boxAndDice.remittance));
      this.boxAndDice.offices[index].sendNotificationToRequestUser = this.boxAndDice.sendNotificationToRequestUser;
      this.boxAndDice.offices[index].sendNotificationToConsultants = this.boxAndDice.sendNotificationToConsultants;
      this.boxAndDice.offices[index].sendRemittanceToRequestUser = this.boxAndDice.sendRemittanceToRequestUser;
      this.boxAndDice.offices[index].sendRemittanceToConsultants = this.boxAndDice.sendRemittanceToConsultants;
      this.boxAndDice.offices[index].allowDisbursementDateOverride = this.boxAndDice.allowDisbursementDateOverride;
      this.boxAndDice.offices[index].referenceType = this.boxAndDice.referenceType;
      this.boxAndDice.offices[index].appendPaymentType = this.boxAndDice.appendPaymentType;
    },

    isOfficeDisabled(data) {
      return (!data.canSync || !data.syncOffice);
    }
  }
};
</script>

<style scoped>
  .office-name {
    min-height: 45px !important;
  }
</style>

